<template>
	<modal class="NoxModalWebsites" name="NoxModalWebsites" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_head">
				<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
				<div class="nox_modal_title">
					<span v-if="noxType == 'addWebsite'" v-html="$store.getters.getLanguageText('5.17', 0)"></span>
					<span v-else-if="noxType == 'deleteWebsite'" v-html="$store.getters.getLanguageText('5.17', 1)"></span>
				</div>
			</div>
			<div class="nox_modal_body">
				<form class="nox_modal_form">
					<div class="nox_modal_info">
						<div v-if="noxType == 'addWebsite'">
							<div class="nox_modal_info_row last">
								<div class="nox_modal_info_row_label">Название сайта:</div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model.trim="noxWebsiteName" placeholder="название сайта" autocomplete="off">
									<div v-html="noxAlertWebsiteName"></div>
								</div>
							</div>
						</div>
						<div v-else-if="noxType == 'deleteWebsite'" v-html="$store.getters.getLanguageText('5.17', 2)"></div>
					</div>
					<div class="nox_modal_alert" v-html="noxAlert"></div>
					<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
					<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
						<button type="button" class="nox_button common" @click="close" v-html="$store.getters.getLanguageText('1.1', 3)"></button>
						<button type="submit" class="nox_button common green" @click="submit" v-if="noxType == 'addWebsite'" v-html="$store.getters.getLanguageText('1.1', 5)"></button>
						<button type="button" class="nox_button common red" @click="axios" v-else-if="noxType == 'deleteWebsite'" v-html="$store.getters.getLanguageText('1.1', 11)"></button>
					</div>
				</form>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxAlertWebsiteName: '',
			noxId: 0,
			noxType: '',
			noxIndex: 0,
			noxStatus: 0,
			noxWebsiteName: '',
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.resetAlerts();
				this.noxId = event.params.id;
				this.noxType = event.params.type;
				this.noxIndex = event.params.index;
				this.noxStatus = event.params.status;
				this.noxWebsiteName = '';
				this.noxIsLoading = false;
				this.noxIsError = false;
			},
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertWebsiteName = '';
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3.18', 0); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3.18', 1); }
				else if (i == 3) { this.noxTemp = this.$store.getters.getLanguageText('1.3.18', 2); }
				else if (i == 4) { this.noxTemp = this.$store.getters.getLanguageText('1.3.18', 3); }
				else             { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			submit: function(event) {
				event.preventDefault(); this.axios();
			},
			axios: function() {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (_this.noxType == 'addWebsite') {
						if (!_this.noxWebsiteName) {
							_this.noxAlertWebsiteName = _this.getError(3);
						}
						else if (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxWebsiteName) || _this.noxWebsiteName.length > 100) {
							_this.noxAlertWebsiteName = _this.getError(4);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/account/websites';
							config.data = { website_name: _this.noxWebsiteName };
							config.method = 'post';
						}
					}
					else if (_this.noxType == 'deleteWebsite') {
						config.url = '/v2/account/websites';
						config.data = { id: _this.noxId };
						config.method = 'delete';
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (_this.noxType == 'addWebsite') {
									_this.$parent.noxWebsitesDataActiveCount++;
									_this.$parent.noxWebsitesData.push(data.data.website_data);
									_this.$parent.$parent.$parent.initAccountData(data.data.account_data);
								}
								else if (_this.noxType == 'deleteWebsite') {
									if (_this.noxStatus == 1) { _this.$parent.noxWebsitesDataActiveCount--; }
									if (_this.noxStatus == 2) { _this.$parent.noxWebsitesDataStoppedCount--; }
									_this.$parent.noxWebsitesDataDeletedCount++;
									_this.$parent.noxWebsitesData.splice(_this.noxIndex, 1);
									_this.$parent.$parent.$parent.initAccountData(data.data.account_data);
								}
								_this.close();
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
									else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError(2); }
									else if (data.response.data.error == 'WEBSITE_NAME_IS_EMPTY') { _this.noxAlertWebsiteName = _this.getError(3); }
									else if (data.response.data.error == 'WEBSITE_NAME_NOT_VALID') { _this.noxAlertWebsiteName = _this.getError(4); }
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError(0); }
								}
								else if (data.response.status == 409) {
									if (data.response.data.error == 'NO_WEBSITES_TO_ADD') { _this.noxAlert = _this.getError(0); }
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			close: function() {
				this.$modal.hide('NoxModalWebsites');
			}
		}
	}
</script>
